import { LitElement, css, html } from 'lit';
import { property, customElement, state } from 'lit/decorators.js';
import { resolveRouterPath, router } from '../../src/router';

import '@shoelace-style/shoelace/dist/components/button/button.js';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

@customElement('app-header')
export class AppHeader extends LitElement {
  @property({ type: Boolean }) enableBack: boolean = false;
  @property({ type: String }) profilePicUrl: string = '';
  @property({ type: String }) pageTitle: string = ''; // New property for page title
  @state() isSidebarOpen = false; // Sidebar state for toggle
  @state() isLoggedIn = false; // New state for logged-in status
  @state() user: any = null;
  @state() isAdmin: boolean = false;
@state() isCaptain: boolean = false;
@state() isPlayer: boolean = false;
@state() isFinance: boolean = false;


  connectedCallback() {
    super.connectedCallback();
    // Load the profile picture URL from localStorage
    this.profilePicUrl = localStorage.getItem('profilePicUrl') || '';
    this.initAuth();
  }

  initAuth() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        this.user = user;
        this.isLoggedIn = true;
        await this.fetchUserRole(user.uid); // Fetch user role from database
      } else {
        this.user = null;
        this.isLoggedIn = false;
        if (window.location.pathname !== resolveRouterPath('home')) {
          router.navigate(resolveRouterPath('login'));
        }
      }
    });
  }

  async fetchUserRole(userId: string) {
    const db = getFirestore(); // Assume Firestore is used
    const userRef = doc(db, `Player/${userId}`); // Adjust to your user collection path
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const userType = userData.type;

      // Set the permissions based on the user type
      this.isAdmin = userType === 'Admin';
      this.isCaptain = userType === 'Captain';
      this.isPlayer = userType === 'Player';
      this.isFinance = userType === 'Finance';
    } else {
      console.error('User document not found');
    }
  }



  async fetchUserName(userId: string) {
    // Fetch user name logic here (if necessary)
    console.log(`Fetch user details for userId: ${userId}`);
  }

  static styles = css`
    header {
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--app-color-primary);
      color: white;
      font-weight: bold;
      font-size: 24px;
      padding: 0 16px;
      position: fixed;
      left: 0;
      top: 0;
      height: 4em;
      width: 100%;
      background-image: url('/assets/images/mainbackground.jpeg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1000;
    }

    .hamburger {
      position: fixed;
      top: 20px;
      left: 20px;
      z-index: 2000;
      cursor: pointer;
      background-color: transparent;
      border: none;
      font-size: 24px;
      color: white;
      pointer-events: auto;
    }

    #sidebar {
      background-color: white;
      position: fixed;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      width: 250px;
      transition: transform 0.3s ease-in-out;
      z-index: 3000;
      box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
      overflow-y: auto;
       height: 100vh;
       color: black;
    }

    #sidebar.open {
      transform: translateX(0%);
    }

    .exit-sidebar {
      background: none;
      border: none;
      font-size: 24px;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 3100;
    }

    .menu-section {
      padding: 10px;
      font-size: 16px;
    }

    .menu-section a {
      display: block;
      padding: 10px;
      text-decoration: none;
      color: black;
    }

    .menu-section a:hover {
      background-color: #f1f1f1;
    }

    .logout-button {
      display: block;
      width: 100%;
      padding: 10px;
      background-color: #e74c3c;
      color: white;
      border: none;
      cursor: pointer;
    }

    .logout-button:hover {
      background-color: #c0392b;
    }

    @media (prefers-color-scheme: light) {
      header {
        color: white

      }
      nav a {
        color: initial;
      }
    }
  `;

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  renderSidebarLinks() {
    return html`
      <div class="welcome-message">Appleton CC</div>
      <div class="menu-section">
        <a href="${resolveRouterPath('home')}">
          <span class="menu-icon">🏠</span>
          <span class="menu-text">Home</span>
        </a>
        <a href="${resolveRouterPath('availability')}">
          <span class="menu-icon">✔️</span>
          <span class="menu-text">Availability</span>
        </a>
        <a href="${resolveRouterPath('fixtures')}">
          <span class="menu-icon">📅</span>
          <span class="menu-text">Fixtures</span>
        </a>
          <a href="${resolveRouterPath('events')}">
          <span class="menu-icon">📅</span>
          <span class="menu-text">Events</span>
        </a>
        <a href="${resolveRouterPath('results')}">
          <span class="menu-icon">🏅</span>
          <span class="menu-text">Results</span>
        </a>
        <a href="${resolveRouterPath('store')}">
           <span class="menu-icon">🛍️</span>
           <span class="menu-text">Club Store</span>
        </a>
        ${this.isAdmin || this.isCaptain
          ? html`
              <a href="${resolveRouterPath('selection')}">
                <span class="menu-icon">📝</span>
                <span class="menu-text">Selection</span>
              </a>
            `
          : ''}
        ${this.isAdmin
          ? html`
              <a href="${resolveRouterPath('enter-fixture')}">
                <span class="menu-icon">✏️</span>
                <span class="menu-text">Enter Fixture</span>
              </a>
              <a href="${resolveRouterPath('enter-event')}">
                <span class="menu-icon">✏️</span>
                <span class="menu-text">Enter Event</span>
              </a>
              <a href="${resolveRouterPath('score-entry')}">
                <span class="menu-icon">🧮</span>
                <span class="menu-text">Score Entry</span>
              </a>
              <a href="${resolveRouterPath('stats-entry')}">
                <span class="menu-icon">📊</span>
                <span class="menu-text">Stats Entry</span>
              </a>
              <a href="${resolveRouterPath('fantasy-entry')}">
                <span class="menu-icon">💰</span>
                <span class="menu-text">Fantasy Entry</span>
              </a>
              <a href="${resolveRouterPath('fine-entry')}">
                <span class="menu-icon">💸</span>
                <span class="menu-text">Fine Entry</span>
              </a>
              <a href="${resolveRouterPath('merge-player-data')}">
                <span class="menu-icon">🔀</span>
                <span class="menu-text">Merge Player</span>
              </a>
              <a href="${resolveRouterPath('news-entry')}">
                <span class="menu-icon">📁</span>
                <span class="menu-text">News Entry</span>
              </a>
                 <a href="${resolveRouterPath('support-admin')}">
                <span class="menu-icon">📁</span>
                <span class="menu-text">Support Admin</span>
              </a>

            `
          : ''}
      </div>

      <div class="menu-section">
        <h2>My Performance</h2>
        ${this.isAdmin || this.isCaptain || this.isPlayer || this.isFinance
          ? html`
              <a href="${resolveRouterPath('batting')}">
                <span class="menu-icon">🏏</span>
                <span class="menu-text">Batting</span>
              </a>
              <a href="${resolveRouterPath('bowling')}">
                <span class="menu-icon">🎯</span>
                <span class="menu-text">Bowling</span>
              </a>
              <a href="${resolveRouterPath('fielding')}">
                <span class="menu-icon">🤲</span>
                <span class="menu-text">Fielding</span>
              </a>
              <a href="${resolveRouterPath('badges')}">
                <span class="menu-icon">🎖️</span>
                <span class="menu-text">Badges</span>
              </a>
              <a href="${resolveRouterPath('leaderboard')}">
                <span class="menu-icon">📈</span>
                <span class="menu-text">Leaderboard</span>
              </a>


      </div>

      <div class="menu-section">
        <h2>My Fantasy</h2>
        <a href="${resolveRouterPath('my-team')}">
          <span class="menu-icon">⚔️</span>
          <span class="menu-text">My Team</span>
        </a>
        <a href="${resolveRouterPath('league')}">
          <span class="menu-icon">🏆</span>
          <span class="menu-text">League</span>
        </a>
        <a href="${resolveRouterPath('fantasy-rules')}">
          <span class="menu-icon">📜</span>
          <span class="menu-text">Fantasy Rules</span>
        </a>
      </div>
         `
          : ''}

      <div class="menu-section">
        <h2>My Profile</h2>

        ${this.isAdmin || this.isCaptain || this.isPlayer || this.isFinance
          ? html`
              <a href="${resolveRouterPath('profile')}">
                <span class="menu-icon">🧑</span>
                <span class="menu-text">Profile</span>
              </a>
                  <a href="${resolveRouterPath('laws')}">
          <span class="menu-icon">⚖️</span>
          <span class="menu-text">Laws of Cricket</span>
        </a>
          <a href="${resolveRouterPath('support-ticket')}">
                <span class="menu-icon">❓</span>
                <span class="menu-text">Support</span>
              </a>
      </div>

            `
          : ''}

        ${this.isAdmin || this.isFinance
          ? html`
              <a href="${resolveRouterPath('finance')}">
                <span class="menu-icon">💼</span>
                <span class="menu-text">Finance</span>
              </a>
            `
          : ''}


      ${this.isLoggedIn
        ? html`
            <div class="separator"></div>
            <div class="menu-section">

              <button class="logout-button" @click="${this.handleLogout}">
                <span class="menu-icon">🚪</span>
                <span class="menu-text">Logout</span>
              </button>
            </div>
          `
        : ''}
    `;
  }

  handleLogout() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log('Logged out');
        this.isLoggedIn = false;
        router.navigate(resolveRouterPath('login'));
      })
      .catch((error) => {
        console.error('Error logging out:', error);
      });
  }

  render() {
    return html`
      <header>
        <!-- Page Title in the Center -->
        <div id="title">${this.pageTitle}</div>

        <!-- Sidebar -->
        <button @click="${this.toggleSidebar}" class="hamburger">☰</button>
        <div id="sidebar" class="${this.isSidebarOpen ? 'open' : ''}">
          <button @click="${this.toggleSidebar}" class="exit-sidebar">✖️</button>
          ${this.renderSidebarLinks()}
        </div>
      </header>
    `;
  }
}
